import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Heading from "../blockHeading/heading"
import "./blockFeaturedContent.scss"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import TripAdvisor from "../tripAdvisor/tripAdvisor"
import {Parallax} from "react-scroll-parallax"

const BlockFeaturedContent = ({ hotelId, content }) => {

  return (
    <section className="blk-two-col">
      <Container>
        <Row>
          <Col md={6}>
            {content.relationships.block_featured_content_2_medias.length > 0 &&
              <GatsbyImage image={getImage(content.relationships.block_featured_content_2_medias[0]?.relationships.field_image.gatsbyImage)} alt={content.relationships.block_featured_content_2_medias[0].relationships.field_image.alt} />
            }
          </Col>
          <Col md={5}>
            {content.relationships.block_featured_content_2_medias.length > 0 &&
              <figure className="smallImage">
                <GatsbyImage image={getImage(content.relationships.block_featured_content_2_medias[1]?.relationships.field_image.gatsbyImage)} alt={content.relationships.block_featured_content_2_medias[1].relationships.field_image.alt} />
              </figure>
            }
            <div className="content">
              <Heading
                caption={content.block_featured_content_2_caption}
                title={content.block_featured_content_2_title}
                description={content.block_featured_content_2_desc.processed}
              />
              {content.block_featured_content_2_link && (
                <Link
                  to={content.block_featured_content_2_link.url}
                  className="simpleLink"
                >
                  {content.block_featured_content_2_link.title}
                </Link>
              )}
            </div>
          </Col>
        </Row>
        <div className="blk-tripAdvisor">
          <Parallax speed={-19}>
            {hotelId && <TripAdvisor hotelId={hotelId}/>}
          </Parallax>
        </div>
      </Container>
    </section>
  )
}

export const query = graphql`
  fragment featuredContent2 on paragraph__block_featured_content_2 {
    block_featured_content_2_caption
    block_featured_content_2_title
    block_featured_content_2_desc {
      processed
    }
    block_featured_content_2_link {
      title
      url
    }
    relationships {
      block_featured_content_2_medias {
        field_image {
          alt
        }
        relationships {
          ...getImage
        }
      }
    }
  }
`

export default BlockFeaturedContent
