import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import MainBannerImage from "../components/common/mainBanner/mainBannerImage"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"
import {
  getCarouselImageOG,
  getHotelBookingCode,
} from "../components/functions"
import BlockFeeds from "../components/common/blockFeeds/blockFeeds"
import BlkExpSig from "../components/common/blockExpSig/blkExpSig"
import Blkmarquee from "../components/common/marquee/marquee"
import BlockRooms from "../components/hotel/blockRooms"
import BlkMeaSlider from "../components/common/blockMeaSlider/blockMeaSlider"
import BlkPushFeat from "../components/common/blockPushFeat/blockPushFeat"
import BlockDeals from "../components/common/blockCarousel/blockDeals"
import BlockFeaturedContent from "../components/common/blockFeaturedContent/blockFeaturedContent"
import BlockConfigExperience from "../components/common/blockConfigExperiance/blockConfigExperience"

export default function Experience({
  data: { nodeHotel, allNodeRoom, allNodeOffer, allTaxonomyTermGenre },
  pageContext,
}) {
  const isSSR = typeof window === "undefined"
  const IntroHotel = React.lazy(() =>
    import("../components/common/introHotel/introHotel")
  )
  const BlockBooking = React.lazy(() =>
    import("../components/common/blockBooking/blockBooking")
  )

  const {
    title,
    field_seo,
    relationships: {
      hotel_carousel: {
        relationships: { carousel_highlight_item: carouselMea },
      },
      hotel_block_rooms: blockRooms,
      hotel_block_deals: blockOfferInfo,
      hotel_marquee_1: marquee1,
      hotel_tiled_exp_block_1: titledExperiences1,
      hotel_marquee_2: marquee2,
      hotel_introduction: hotelIntro,
      hotel_block_slider_1: blockMeaSlider,
      hotel_featured_content_block_1: featuredContent,
      hotel_featured_content_block_2: featuredContent2,
      hotel_hotel: { drupal_id: hotelId },
    },
  } = nodeHotel

  const intl = useIntl()

  const carouselFiltered = carouselMea.filter(
    carousel => carousel.relationships.media !== null
  )

  const seoData = setSeoData({
    lang: intl.locale,
    title: title,
    field_seo: field_seo,
    image: process.env.GATSBY_URL + getCarouselImageOG(carouselFiltered),
  })

  let locale
  locale = intl.locale

  return (
    <Layout pageContext={pageContext} pageTitle={title} pageClass="pageHotel">
      {carouselFiltered.length > 0 && (
        <MainBannerImage items={carouselFiltered} />
      )}
      <Seo data={seoData} pageContext={pageContext} />
      {!isSSR && (
        <React.Suspense fallback="loading.... ">
          {hotelIntro && <IntroHotel content={hotelIntro} hotelId={hotelId} />}
          {marquee1 && <Blkmarquee content={marquee1} />}
          {titledExperiences1 && <BlkExpSig content={titledExperiences1} />}
          {allNodeRoom.nodes.length > 0 && (
            <BlockRooms rooms={allNodeRoom.nodes} content={blockRooms} />
          )}
          {blockMeaSlider && <BlkMeaSlider content={blockMeaSlider} />}
          {featuredContent2 && (
            <BlockFeaturedContent
              hotelId={hotelId}
              content={featuredContent2}
            />
          )}
          {/* {hotelId === "c2d4a773-ee3f-4b1d-9a29-21eea272203e" && (
            <BlockConfigExperience
              locale={pageContext.locale}
              genres={allTaxonomyTermGenre.nodes}
              hotel="VerandaPaulEtVirginie"
            />
          )}
          {hotelId === "708a5a46-6394-4f2d-a3f5-7a063a6ce201" && (
            <BlockConfigExperience
              locale={pageContext.locale}
              genres={allTaxonomyTermGenre.nodes}
              hotel="VerandaPointeauxBiches"
            />
          )}
          {hotelId === "0e204f9f-028c-447c-9271-f2a9f94c2d74" && (
            <BlockConfigExperience
              locale={pageContext.locale}
              genres={allTaxonomyTermGenre.nodes}
              hotel="VerandaGrandBaie"
            />
          )}{" "}
          {hotelId === "8c705b72-3dbc-402e-89ba-3ca707a1d53f" && (
            <BlockConfigExperience
              locale={pageContext.locale}
              genres={allTaxonomyTermGenre.nodes}
              hotel="VerandaTamarin"
            />
          )}{" "}
          {hotelId === "9f17836a-f3dd-42ae-a89e-e601e7ec5846" && (
            <BlockConfigExperience
              locale={pageContext.locale}
              genres={allTaxonomyTermGenre.nodes}
              hotel="VerandaPalmarBeach"
            />
          )} */}
          {marquee2 && <Blkmarquee content={marquee2} />}
          {featuredContent && <BlkPushFeat content={featuredContent} />}
          {allNodeOffer.nodes.length > 0 && blockOfferInfo !== null && (
            <BlockDeals
              //hotelId={hotelId}
              content={blockOfferInfo}
              offers={allNodeOffer.nodes}
            />
          )}
          <BlockFeeds locale={locale} />
        </React.Suspense>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!, $language: String!, $hotelTaxonomyDrupalId: String!) {
    nodeHotel(id: { eq: $id }, langcode: { eq: $language }) {
      ...hotelFields
    }
    allNodeRoom(
      filter: {
        title: { ne: "Dummy" }
        langcode: { eq: $language }
        relationships: {
          room_hotel: { drupal_id: { eq: $hotelTaxonomyDrupalId } }
        }
      }
    ) {
      nodes {
        field_weight
        title
        room_description {
          processed
        }
        room_reservation_link {
          title
          uri
        }
        featured_cta_text
        path {
          alias
          translated_urls
          langcode
        }
        relationships {
          room_carousel {
            ...getCarouselItems
          }
          room_categories {
            name
          }
        }
      }
    }
    allNodeOffer(
      filter: {
        title: { ne: "Dummy" }
        langcode: { eq: $language }
        relationships: {
          offer_hotel: { drupal_id: { eq: $hotelTaxonomyDrupalId } }
        }
      }
    ) {
      nodes {
        ...deals
      }
    }
    allTaxonomyTermGenre(
      filter: { langcode: { eq: $language } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        name
        id
      }
    }
  }
`
