import React from "react"
import Slider from "react-slick"
import { Row, Col } from "react-bootstrap"
import "../../assets/scss/plugins/_slick.scss"
import { Link } from "gatsby"
import { generateUrlByLocale } from "../../services/intl.service"
import Media from "./media"
import { useIntl } from "gatsby-plugin-react-intl"
import HTMLReactParser from "html-react-parser"
import Heading from "./heading"
import StyledButton from "./styledButton"
import { useState } from "react"

const RoomCarouselItems = props => {
  const intl = useIntl()

  const rooms = props.rooms

  const listItemsCat = rooms.map((roomdet, index) => (
    <span key={index}>{roomdet?.relationships?.room_categories?.name}</span>
  ))

  const [sliderNav, setSliderNav] = useState(null)
  const [sliderMain, setsliderMain] = useState(null)

  const settings = {
    infinite: true,
    useTransform: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "20px",
        },
      },
    ],
  }

  const settingsRoomCarousel = {
    infinite: false,
    useTransform: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0",
        },
      },
    ],
  }

  const settingsSliderCat = {
    infinite: true,
    useTransform: false,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    centerMode: true,
    arrows: true,
    dots: false,
    vertical: true,
    centerPadding: "40px",
    focusOnSelect: true,
  }

  const listItems = rooms.map((room, index) => (
    <div key={index}>
      <div>
        <Slider {...settingsRoomCarousel}>
          {room.relationships.room_carousel?.relationships.carousel_highlight_item.map((image, index) => (
            (image.relationships.media !== null) ? (
              <div key={index}>
                <Media
                  media={image.relationships.media}
                />
              </div>
            ) : null
          ))}
        </Slider>
        <div className="sliderInfos">
          {room.room_description &&
            <Heading title={room.title} heading={3} description={HTMLReactParser(room.room_description.processed)}
            />}
          <Link className="simpleLink" to={generateUrlByLocale(room.path.langcode, room.path.alias)}>
            {intl.formatMessage({ id: "learn_more" })}
          </Link>
          {room.room_reservation_link &&
            <StyledButton path={room.room_reservation_link.uri} title={room.room_reservation_link.title} />
          }
        </div>
        <Link
          to={generateUrlByLocale(room.path.langcode, room.path.alias)}
          className="hiddenLink"
        ></Link>
      </div>
    </div>
  ))

  return (
    <Row>
      <Col>
        <Slider {...settings} asNavFor={sliderMain} ref={slider => (setSliderNav(slider))} className="sliderMain">
          {listItems}
        </Slider>

        <Slider {...settingsSliderCat} asNavFor={sliderNav} ref={slider => (setsliderMain(slider))} className="sliderNav">
          {listItemsCat}
        </Slider>
      </Col>
    </Row>
  )
}

export default RoomCarouselItems
