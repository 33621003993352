import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Heading from "../common/blockHeading/heading"
import RoomCarouselItems from "../common/roomCarouselItems"
import "./hotel.scss"
import { sortByFieldWeight } from "../functions"

const BlockRooms = props => {
  let roomList = sortByFieldWeight(props.rooms)
  const content = props.content

  return (
    <section className="blkMeaRooms">
      <Container>
        <Row>
            <Col className="colFirstRow">
                <Heading
                    caption={content.block_rooms_caption}
                    title={content.block_rooms_title}
                    description={content.block_rooms_description}
                    link={content.block_rooms_link}
                />
            </Col>
        </Row>
        <RoomCarouselItems rooms={roomList} />
      </Container>
    </section>
  )
}

export default BlockRooms
